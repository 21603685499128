import React from 'react';

export default function Footer() {
  return (
    <div role="footer" className='p-5 text-center'>
        <text className="text-xl">
            rachel shi 2024
        </text>
    </div>
  );
}